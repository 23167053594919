import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import FunFact from "../FunFact";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import TeamSlider from "../Slider/TeamSlider";
import Spacing from "../Spacing";

export default function AboutPage() {
  pageTitle("About");

  const funfaceData = [
    {
      title: "Process",
      factNumber: "48 hr",
    },
    {
      title: "Interviews",
      factNumber: "1000  ",
    },
    {
      title: "Growing at",
      factNumber: "80%   ",
    },
    {
      title: "Interview Selection Ratio",
      factNumber: "90%   ",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="The center of talent and creativity"
              subtitle="About ControlShift"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Our company specializes in providing staffing solutions for MERN
                and MEAN stack developers, catering to businesses seeking
                exceptional talent. Our primary mission is to ensure a swift and
                seamless hiring process for our clients, and to this end, we
                strive to onboard qualified candidates within a span of two days
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_1.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          title="Why We Stand Out in the Crowd?"
          subtitle="We're a team of dedicated professionals who are passionate about helping businesses succeed. We take the time to get to know our clients and candidates on a personal level, and we're committed to building strong, lasting relationships that benefit everyone involved."
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="A Team of Seasoned Experts"
              subtitle="What Sets Us Apart"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                As a premier staffing firm, we specialize in offering
                top-quality MERN and MEAN stack developers to businesses seeking
                exceptional talent. Our establishment in 2021 reflects our
                commitment to helping our clients achieve their goals by
                providing highly skilled professionals in just two days.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
              Our core mission is to streamline the hiring process by meticulously
                screening each candidate to ensure they possess the skills and
                expertise required to excel in their roles.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Team Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div>
      {/* End Team Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Setup a call with us & Coffee is on us"
          btnLink="/contact us"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Div from "../Div";

export default function RecentPost({ title, data }) {
  const [archives, setArchives] = useState();
  useEffect(() => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@tech_69544"
      )
      .then((res) => setArchives(res.data?.items));
  }, []);
  return (
    <>
      <h4 className="cs-sidebar_widget_title">{title}</h4>
      <ul className="cs-recent_posts">
        {archives?.map((item, index) => (
          <li key={index}>
            <Div className="cs-recent_post">
              {/* <Link to={item.href} className="cs-recent_post_thumb"> */}
              <Link to={`/blog${item.guid.replace('https://medium.com/p',"")}`} className="cs-recent_post_thumb">
                {/* <Div
                  className="cs-recent_post_thumb_in cs-bg"
                  style={{ backgroundImage: `url(${item.thumb})` }}
                /> */}
                <Div
                  className="cs-recent_post_thumb_in cs-bg"
                  style={{ backgroundImage: `url(${item.thumbnail})` }}
                />
              </Link>
              <Div className="cs-recent_post_info">
                <h3 className="cs-recent_post_title">
                  {/* <Link to={item.href}>{item.title}</Link> */}
                  <Link to={`/blog${item.guid.replace('https://medium.com/p',"")}`}>{item.title.replace(/&amp;/g, "&")}</Link>
                </h3>
                {/* <Div className="cs-recent_post_date cs-primary_40_color">
                  {item.date}
                </Div> */}
                <Div className="cs-recent_post_date cs-primary_40_color">
              {new Date(`${item.pubDate}`).toLocaleDateString(
                    "en-us",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                  </Div>
              </Div>
            </Div>
          </li>
        ))}
      </ul>
    </>
  );
}

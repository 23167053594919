import React from 'react'
import Div from '../Div'

export default function TextWidget({logoSrc, logoAlt,logosift, text}) {
  return (
    <Div className="cs-text_widget">
      <h2 className='cs-widget_title'style={{fontSize:"22px"}}><span style={{color:'#FF4A17',fontSize:"22px"}} >{logoSrc}</span>{logosift}</h2>
      {/* <img src={logoSrc} alt={logoAlt} /> */}
      <p>{text}</p>
    </Div>
  )
}

import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import Section from "../Div";
import "./skills.css";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ContactPage() {
  const [tab, setTab] = useState("Gurugram");
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [project,setProject] = useState('');
  const [mobile,setMobile] = useState('');
  const [inquiry,setInquiry] = useState('');
  const [date,setDate] = useState(new Date());
  var currentDate=`${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
 
  const iframeRef = useRef(null);
  function handleIframeLoad() {
    const iframeDocument = iframeRef.current.contentWindow.document;
    const mapElement = iframeDocument.getElementById("my-map");
    mapElement.classList.add("highlighted");
  }
  const notify = () => toast("Thank you for your request. We will reach out to you soon.");
  pageTitle("Contact Us");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmitHandel = (e) =>{
    e.preventDefault();
    const data=[{'id':Math.random(1-9),"Name":name,'Email':email,'ProjectType':project,'Mobile':mobile,'Inquiry':inquiry,"Date":currentDate}];
    console.log(data)
    axios.post('https://sheetdb.io/api/v1/cidphhzrh3ly2', {data})
    .then(res=>console.log(res.data))
    setName('');
    setEmail('');
    setProject('');
    setMobile('');
    setInquiry('');
   
  }
  return (
    <>
    <ToastContainer />
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting In Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" className="row" onSubmit={onSubmitHandel}>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input type="text" className="cs-form_field" value={name} onChange={(e)=>setName(e.target.value)} required/>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input type="email" className="cs-form_field" value={email} onChange={(e)=>setEmail(e.target.value)} required/>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input type="text" className="cs-form_field" value={project} onChange={(e)=>setProject(e.target.value)} required/>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input type="number" className="cs-form_field"  value={mobile} onChange={(e)=>setMobile(e.target.value)} required/>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Inquiry*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  value={inquiry}
                  onChange={(e)=>setInquiry(e.target.value)}
                  required
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" onClick={notify}>
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div
        className="cs-google_map"
        // style={{ position: "relative" }}
      >
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd"
          allowFullScreen
          title="Google Map"
        /> */}
        {tab === "Gurugram" ? (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.679513992842!2d77.08254521478013!3d28.459075898661656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18c37bbfd43b%3A0x9954587fcd977704!2s944%2C%20Vyapar%20Kendra%20Rd%2C%20Block%20C%2C%20Sushant%20Lok%20Phase%20I%2C%20Sector%2043%2C%20Gurugram%2C%20Haryana%20122002!5e0!3m2!1sen!2sin!4v1678861499694!5m2!1sen!2sin"
            allowFullScreen
            title="Google Map"
            onLoad={handleIframeLoad}
          ></iframe>
        ) : (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.968406528746!2d77.6304496145528!3d12.909752119726036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1492e26571bd%3A0x31420898a332247!2s921%2C%205th%20Main%20Rd%2C%20Sector%207%2C%20HSR%20Layout%2C%20Bengaluru%2C%20Karnataka%20560102!5e0!3m2!1sen!2sin!4v1678856595377!5m2!1sen!2sin"
            allowFullScreen
            title="Google Map"
          />
        )}
      </Div>
      <Spacing lg="50" md="40" />

      <div
        style={{
          marginTop: "160px",
          marginBottom: "-120px",
          marginRight: "80px",
          // calc(-140% - 5%)
        }}
      >
        <Section className="position-relative" style={{position:'relative'}}>
          <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font" style={{position:'absolute',}}>
            <li
              className={tab === "Gurugram" ? "active" : ""}
              onClick={() => setTab("Gurugram")}
            >
              Gurugram
            </li>
            <li
              className={tab === "Bengaluru" ? "active" : ""}
              onClick={() => setTab("Bengaluru")}
            >
              Bengaluru
            </li>
          </ul>
        </Section>
      </div>
    </>
  );
}

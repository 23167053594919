import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {
  const copyrightLinks = [
    {
      title: 'Terms of Use',
      href: '/'
    },
    {
      title: 'Privacy Policy',
      href: '/'
    }
  ]
  
  const serviceMenu = [
    {
      title: 'Staff Augmentation',
      href: '/service/Staff Augmentation details'
    },
    {
      title: 'WEB development',
      href: '/service/WEB development details'
    },
    {
      title: 'UI/UX design',
      href: '/service/UI-UX details'
    },
    {
      title: 'App development',
      href: '/service/App development details'
    },
  ]

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6" >
              <Div className="cs-footer_item">
                <TextWidget 
                  // logoSrc='/images/footer_logo.svg' 
                  logoSrc='Control'
                  logosift='Shift'
                  logoAlt='Logo'
                  text ='We provide businesses with skilled professionals and specialized expertise for complex technology projects, enabling efficient digital transformation with minimized risk and a focus on quality, collaboration, and innovation.'
                />
                <SocialWidget/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6" style={{paddingLeft:'5%'}}>
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='Services'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Contact Us'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6" style={{paddingLeft:'6%'}}>
              <Div className="cs-footer_item">
                <Newsletter 
                  title='Specific Inquiry?' 
                  subtitle='At vero eos et accusamus et iusto odio as part dignissimos ducimus qui blandit.' 
                  placeholder='example@gmail.com'
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2022 ControlShift.in</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/>
          </Div>
        </Div>
      </Div>
    </footer>
  )
}

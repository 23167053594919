import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialThumb: '/images/Moolyafinal.svg',
       //testimonialThumb: '/images/testimonial_2.jpeg',
       testimonialText:
         "I have worked with many recruiting firms in the past. I was impressed with the way they handled the entire process. ControlShift's team is professional, helpful and informative. The overall experience was amazing and I would recommend ControlShift to everyone!",
       avatarName: 'Moolya Testing',
       avatarDesignation: '',
       ratings: '5',
     },
    {
      testimonialThumb: '/images/Aurigafinal.svg',
      // testimonialThumb: '/images/testimonial_1.jpeg',
      testimonialText:
        "I’m so grateful to have found ControlShift. The team really showed great initiative, and helped me with every question I had. ControlShift truly cares and focuses on setting your company up for success",
      avatarName: 'Auriga',
      // avatarDesignation: 'CEO AT TECH',
      avatarDesignation: '',
      ratings: '4',
    },
    {
      testimonialThumb: '/images/Moolyafinal.svg',
       //testimonialThumb: '/images/testimonial_2.jpeg',
       testimonialText:
         "I have worked with many recruiting firms in the past. I was impressed with the way they handled the entire process. ControlShift's team is professional, helpful and informative. The overall experience was amazing and I would recommend ControlShift to everyone!",
       avatarName: 'Moolya Testing',
       avatarDesignation: '',
       ratings: '5',
     },
     {
      // testimonialThumb: '/images/testimonial_3.jpeg',
      testimonialThumb: '/images/Supersourcing.svg',
       testimonialText:
         "Controlshift is one of those IT agencies, who are dedicated to work on quality along with quick turnaround time. They are quick in understanding our requirements and the engineers provided to us are class apart. We look forward to our long term business association",
       avatarName: 'SuperSourcing',
       avatarDesignation: '',
       ratings: '4.5',
     },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}

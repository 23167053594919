import { Icon } from '@iconify/react'
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom'

export default function SideMenuWidget({title, data}) {
  const [descriptionTitle,setDescriptionTite] = useState();
  const [archives,setArchives] = useState();
  useEffect(() => {
    axios.get(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@tech_69544"
    )
      .then((res) => {
        setDescriptionTite(res.data?.items[2].categories)
        setArchives(res.data?.items)
      });
  }, []);

  return (
    <>
      <h4 className="cs-sidebar_widget_title">{title}</h4>
      <ul className='cs-side_menu_widget'>
        {title==='Categories' && descriptionTitle?.map((item, index)=> (
          <li key={index}>
            <Icon icon="material-symbols:keyboard-double-arrow-right-rounded" />
            <Link to={item.url} key={index}>{item.charAt(0).toUpperCase() + item.slice(1)}</Link>
          </li>
        ))}
      
      </ul>
    </>
  )
}

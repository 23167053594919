import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Pagination({ postsPerpage, totalPosts, paginate }) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageNumbers = [];
  // console.log(pageNumbers);
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerpage); i++) {
    pageNumbers.push(i);
  }
  const handlePaginate = (pageNumber) => {
    if (pageNumber) {
      setCurrentPage(pageNumber);
      paginate(pageNumber);
    } else {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        paginate(currentPage + 1);
      }
    }
  }
 
  return (
    <ul className="cs-pagination_box cs-center cs-white_color cs-mp0 cs-semi_bold">
      {pageNumbers.map((number) => (
        <li onClick={() => handlePaginate(number)}>
          <Link className= {currentPage==number?"cs-pagination_item cs-center active":"cs-pagination_item cs-center"} to="/blog">
            {number}
          </Link>
        </li>
      ))}

      {/* <li>
        <Link className="cs-pagination_item cs-center" to="/blog">2</Link>
      </li>
      <li>
        <Link className="cs-pagination_item cs-center" to="/blog">3</Link>
      </li>
      <li>
        <Link className="cs-pagination_item cs-center" to="/blog">4</Link>
      </li> */}
      <li onClick={() => handlePaginate()}>
        <Link to="#" className="cs-pagination_item cs-center">
          <Icon icon="akar-icons:chevron-right" />
        </Link>
      </li>
    </ul>
  );
}

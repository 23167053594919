
export const pageTitle = (title) => {

  // return ( document.title = title + " - ControlShift");
  return ( document.title ="ControlShift");
  // return ( document.title =  ":in 48 hours! Hire developers" +"ControlShift ");

  // var title = 'ControlShift : Hire developers in 48 hours!'; // Get the current title of the document
  
  // var i = 0; // Initialize a counter variable
  
  // function scrollTitle() {
  //   document.title = title.substring(i) + title.substring(0, i); // Update the title
  //   i++; // Increment the counter variable
  //   if (i > title.length) { // Reset the counter variable
  //     i = 0;
  //   }
  //   setTimeout(scrollTitle, 1000); // Add a delay between each scroll
  // }
  
  // scrollTitle(); // Call the scrollTitle function to start scrolling the title
  
  // return ( document.title = title);
}

// export const pageTitle = (title) => {

//   // var title = 'ControlShift : Hire developers in 48 hours!'; // Get the current title of the document Hire Developers in 48 Hours! ControlShift
//   var title = 'Hire Developers in 48 Hrs'; // Get the current title of the document Hire Developers in 48 Hours! ControlShift
//   var i = 0; // Initialize a counter variable

//   function scrollTitle() {
//     document.title = title.substring(i) + title.substring(0, i); // Update the title
//     i++; // Increment the counter variable
//     if (i > title.length) { // Reset the counter variable
//       i = 0;
//     }
//     setTimeout(scrollTitle, 600); // Add a delay between each scroll
//   }
  
//   scrollTitle(); // Call the scrollTitle function to start scrolling the title

//   // Add CSS for smooth animation
//   const style = document.createElement('style');
//   style.textContent = `
//   title {
//     overflow: hidden;
//     white-space: nowrap;
//     animation: scrollTitle 20s linear infinite;
//     transform: translateZ(0); /* Add hardware acceleration */
//   }
  
//   @keyframes scrollTitle {
//     0% {
//       transform: translateY(0);
//     }
//     100% {
//       transform: translateY(-100%);
//     }
//   }
//   `;
//   document.head.appendChild(style);

//   return (document.title = title);
// }

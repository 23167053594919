import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Div from "../Div";
import Post from "../Post";

export default function PostSlider() {
  const [data, setData] = useState([]);

  // const postData = [
  //   {
  //     url: "/blog/blog-details",
  //     src: "/images/post_1.jpeg",
  //     alt: "Post",
  //     date: "07 Mar 2022",
  //     title: "How to keep fear from ruining your art business with confident",
  //   },
  //   {
  //     url: "/blog/blog-details",
  //     src: "/images/post_2.jpeg",
  //     alt: "Post",
  //     date: "10 Feb 2022",
  //     title: "Artistic mind will be great for creation anything",
  //   },
  //   {
  //     url: "/blog/blog-details",
  //     src: "/images/post_3.jpeg",
  //     alt: "Post",
  //     date: "05 Mar 2022",
  //     title: "A.I will take over all job for human within next year",
  //   },
  //   {
  //     url: "/blog/blog-details",
  //     src: "/images/post_1.jpeg",
  //     alt: "Post",
  //     date: "07 Mar 2022",
  //     title: "How to keep fear from ruining your art business with confident",
  //   },
  //   {
  //     url: "/blog/blog-details",
  //     src: "/images/post_2.jpeg",
  //     alt: "Post",
  //     date: "10 Feb 2022",
  //     title: "Artistic mind will be great for creation anything",
  //   },
  //   {
  //     url: "/blog/blog-details",
  //     src: "/images/post_3.jpeg",
  //     alt: "Post",
  //     date: "05 Mar 2022",
  //     title: "A.I will take over all job for human within next year",
  //   },
  // ];

  // const fetchQuotes = () => {
  //   const options = {
  //     method: 'GET',
  //     url: 'https://medium2.p.rapidapi.com/user/id_for/tech_69544',
  //     headers: {
  //       'X-RapidAPI-Key': 'fa2e9c2e48msha685b19ecb4982dp199ef1jsn1fddc2c7446d',
  //       'X-RapidAPI-Host': 'medium2.p.rapidapi.com'
  //     }
  //   };
    
  //   axios.request(options).then(function (response) {
  //     console.log(response.data);
  //   }).catch(function (error) {
  //     console.error(error);
  //   });
  // };
  // "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@tech_69544",
  useEffect(() => {
    axios
      .get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@tech_69544")
      .then((res) => {
        setData(res.data?.items);
      })
      .catch((err) => {
        console.log(err);
      });
    // fetchQuotes();
  }, []);
  // console.log(data);
  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24">

      {/* {postData.map((item, index) => (
        <Div key={index}>
          <Post
            url={item.url}
            src={item.src}
            alt={item.alt}
            date={item.date}
            title={item.title}
          />
        </Div>
      ))} */}

      {data.map((item, index) => (
        <Div key={index}>
          <Post
            url={'/blog'}
            src={item.thumbnail}
            alt={"post"}
            date={new Date(`${item.pubDate}`).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
            title={item.title.replace(/&amp;/g, "&")}
          />
        </Div>
      ))}

    </Slider>
  );
}

import React, { useEffect, useState } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Pagination from "../Pagination";
import PostStyle2 from "../Post/PostStyle2";
import Div from "../Div";
import Sidebar from "../Sidebar.jsx";
import Spacing from "../Spacing";
import axios from "axios";
import ReactPaginate from "react-paginate";
export default function BlogPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerpage] = useState(2);
  // Get current posts
  const indexOfLastPost = currentPage * postsPerpage;
  const indexOfFirstPost = indexOfLastPost - postsPerpage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  // Changes page
  const paginate = (pageNumbers) => setCurrentPage(pageNumbers);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginate]);
  pageTitle("Blog");
  // console.log(currentPage);
  // const postData = [
  //   {
  //     thumb: "/images/post_4.jpeg",
  //     title: "A.I will take all human job within next year",
  //     subtitle:
  //       "Elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique. Tortor posuere ac ut consequat semper viverra nam libero justo. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien et. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Purus sit amet luctus venenatis lectus. Nunc aliquet bibendum enim facilisis. Pretium viverra suspendisse potenti nullam ac tortor vitae.",
  //     date: "07 Mar 2022",
  //     category: "Tech",
  //     categoryHref: "/blog",
  //     href: "/blog/blog-details",
  //   },
  //   {
  //     thumb: "/images/post_5.jpeg",
  //     title: "Creative studio programm coming soon",
  //     subtitle:
  //       "Elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique. Tortor posuere ac ut consequat semper viverra nam libero justo. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien et. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Purus sit amet luctus venenatis lectus. Nunc aliquet bibendum enim facilisis. Pretium viverra suspendisse potenti nullam ac tortor vitae.",
  //     date: "05 Mar 2022",
  //     category: "Photography",
  //     categoryHref: "/blog",
  //     href: "/blog/blog-details",
  //   },
  //   {
  //     thumb: "/images/post_6.jpeg",
  //     title: "Artistic mind will be great for creation",
  //     subtitle:
  //       "Elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique. Tortor posuere ac ut consequat semper viverra nam libero justo. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien et. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Purus sit amet luctus venenatis lectus. Nunc aliquet bibendum enim facilisis. Pretium viverra suspendisse potenti nullam ac tortor vitae.",
  //     date: "04 Mar 2022",
  //     category: "Tech",
  //     categoryHref: "/blog",
  //     href: "/blog/blog-details",
  //   },
  // ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@tech_69544",
        {
          params: {
            last_page: 1,
            page,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setData(res.data?.items);
        setError(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setError(true);
      });
  }, [page]);
  // console.log(data);
  return (
    <>
      <PageHeading
        title="Our Blog"
        bgSrc="/images/blog_hero_bg.jpeg"
        pageLinkText="Blog"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-8">
            {currentPosts.map((item, index) => (
              <Div key={index}>
                <PostStyle2
                  thumb={item.thumbnail}
                  title={item.title.replace(/&amp;/g, "&")}
                  subtitle={item.content.replace(/<[^>]*>/gm, "")}
                  date={new Date(`${item.pubDate}`).toLocaleDateString(
                    "en-us",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                  category={item.author}
                  categoryHref={item.categoryHref}
                  href={`/blog${item.guid}`}
                  link={item.link}
                />
                {/* {postData.length > index + 1 && <Spacing lg="95" md="60" />} */}
                {<Spacing lg="95" md="60" />}
               
              </Div>
            ))}
            <Spacing lg="60" md="40" />
            <Pagination
             postsPerpage={postsPerpage}
             totalPosts = {data.length}
             paginate={paginate}
            />
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg="0" md="80" />
            <Sidebar />
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Setup a call with us & Coffee is on us"
          btnLink="/contact us"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}

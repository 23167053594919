import React, { Fragment } from "react";
import "./skills.css";
// import crayonwelcome from "../../assets/Images/crayonwelcome.png";


//import frontend image
import front1 from "../../assets/Images/front1.svg";
import front2 from "../../assets/Images/front2.svg";
import front4 from "../../assets/Images/front4.svg";
import front3 from "../../assets/Images/front3.svg";
import front5 from "../../assets/Images/front5.svg";
import front6 from "../../assets/Images/front6.svg";
import front7 from "../../assets/Images/front7.svg";
import front8 from "../../assets/Images/front8.svg";
import front9 from "../../assets/Images/front9.svg";
import front10 from "../../assets/Images/front10.svg";
import front11 from "../../assets/Images/front11.svg";  
import front12 from "../../assets/Images/front12.png";


//import backend image
import back1 from "../../assets/Images/back1.svg";
import back2 from "../../assets/Images/back2.svg";
import back3 from "../../assets/Images/back3.svg";
import back4 from "../../assets/Images/back4.svg";
import back5 from "../../assets/Images/back5.svg";
import back6 from "../../assets/Images/back6.svg";
import back7 from "../../assets/Images/back7.png";
import back9 from "../../assets/Images/back9.svg";

export const frontendimagedata = [
  {
    image: front1,
  },
  {
    image: front2,
  },
  {
    image: front3,
  },
  {
    image: front4,
  },
  {
    image: front5,
  },
  {
    image: front6,
  },
  {
    image: front7,
  },
  {
    image: front8,
  },
  {
    image: front9,
  },
  {
    image: front11,
  },
  {
    image: front12,
  },
  {
    image: front1,
  },
  {
    image: front2,
  },
  {
    image: front3,
  },
  {
    image: front4,
  },
  {
    image: front5,
  },
  {
    image: front6,
  },
  {
    image: front7,
  },
  {
    image: front8,
  },
  {
    image: front9,
  },
  {
    image: front11,
  },
  {
    image: front12,
  },
];

export const nocodedata = [
  {
    image: front10,
  },
  {
    image: back2,
  },
  {
    image: back3,
  },
  {
    image: front10,
  },
  {
    image: back2,
  },
  {
    image: back3,
  },
  {
    image: front10,
  },
  {
    image: back2,
  },
  {
    image: back3,
  },
  {
    image: front10,
  },
  {
    image: back2,
  },
  {
    image: back3,
  },
  {
    image: front10,
  },
  {
    image: back2,
  },
  {
    image: back3,
  },
  {
    image: front10,
  },
  {
    image: back2,
  },
  {
    image: back3,
  },
  {
    image: front10,
  },
  {
    image: back2,
  },
  {
    image: back3,
  },
  {
    image: front10,
  },
  {
    image: back2,
  },
  {
    image: back3,
  },
 
];
export const backendimagedata = [
  {
    image: back1,
  },
  {
    image: back4,
  },
  {
    image: back5,
  },
  {
    image: back6,
  },
  {
    image: back7,
  },
  {
    image: back9,
  },
  {
    image: back1,
  },
  {
    image: back4,
  },
  {
    image: back5,
  },
  {
    image: back6,
  },
  {
    image: back7,
  },
  {
    image: back9,
  },
  {
    image: back1,
  },
  {
    image: back4,
  },
  {
    image: back5,
  },
  {
    image: back6,
  },
  {
    image: back7,
  },
  {
    image: back9,
  },
  {
    image: back1,
  },
  {
    image: back4,
  },
  {
    image: back5,
  },
  {
    image: back6,
  },
  {
    image: back7,
  },
  {
    image: back9,
  },
];
export const allTechnology = [
  {
    image: front1,
  },
  {
    image: front2,
  },
  {
    image: front3,
  },
  {
    image: front4,
  },
  {
    image: front5,
  },
  {
    image: front6,
  },
  {
    image: front7,
  },
  {
    image: front8,
  },
  {
    image: front9,
  },
  {
    image: front11,
  },
  {
    image: front12,
  },

  {
    image: back1,
  },
  {
    image: back2,
  },
  {
    image: back3,
  },
  {
    image: back4,
  },
  {
    image: back5,
  },
  {
    image: back6,
  },
  {
    image: back7,
  },
  {
    image: back9,
  },
  

]
const Skills = () => {
  return (
    <Fragment>
      <div className="expertise-main-container">
        <div className="expertise-main-container-content">
          {/* frontend image */}
          <div className="expertise-main-container-content-frontend-main">
            <div className="expertise-main-container-content-frontend-heading">
              <span>Front-end</span>
            </div>

            <div className="expertise-main-container-content-frontend-image">
              {frontendimagedata.map((ele) => (
                <div className="expertise-main-container-frontend-grey-box">
                  <img src={ele.image} alt="" />
                </div>
              ))}
            </div>
          </div>


          {/* backend image */}

          <div className="expertise-main-container-content-backend-main">
            <div className="expertise-main-container-content-frontend-heading">
              <span>Back-end</span>
            </div>

            <div className="expertise-main-container-content-backend-image">
              {backendimagedata.map((ele) => (
                <div className="expertise-main-container-backend-grey-box">
                  <img src={ele.image} alt="" />
                </div>
              ))}
            </div>
          </div>
          {/* no-code less code image */}

          <div className="expertise-main-container-content-backend-main">
            <div className="expertise-main-container-content-frontend-heading">
              <span>No-code/Low-code</span>
            </div>

            <div className="expertise-main-container-content-frontend-image  expertise-main-container-content-backend-image">
              {nocodedata.map((ele) => (
                <div className="expertise-main-container-backend-grey-box">
                  <img src={ele.image} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Skills;

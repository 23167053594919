import { Icon } from '@iconify/react';
import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Team from '../Team';

export default function TeamSlider() {
  /** Team Member Data **/ 
  const teamData = [
  
    {
      // memberImage: '/images/MuskanFinal2.png',
     memberImage: '/images/Vivek.jpeg', 
      memberName: 'Vivek Kumar',
      memberDesignation: 'Full Stack Developer',
       memberSocial: {
        linkedin: 'https://www.linkedin.com/in/vivek-kumar-498507b8/',
        // twitter: '/',
        // youtube: '/',
        facebook: 'https://www.facebook.com/people/Vivek-Kumar/pfbid0UrtStx3D5CaNQuTkiHrcnUiZuCiuhoNygbNg1wYRMoQcQRpXdAqYyYGn7FYZv8Tel/',

      },
    },
    {
      memberImage: '/images/Shakir.jpg',
      memberName: 'Md. Shakir Hussain',
      memberDesignation: 'React Developer',
       memberSocial: {
        linkedin: 'https://www.linkedin.com/in/md-shakir-hussain/',
        // twitter: '/',
        // youtube: '/',
         Github: 'https://github.com/mshakir082',
         Instagram: 'https://www.instagram.com/lordofgod08/',
        // facebook: '/',
      },
    },
    {
      memberImage: '/images/Gyan.jpg',
      memberName: 'Gyan Prakash Mishra',
      memberDesignation: 'Jr. Full stack Developer',
       memberSocial: {
        linkedin: 'https://www.linkedin.com/in/prakash-gyanu/',
        // twitter: '/',
        // youtube: '/',
         Instagram: 'https://www.instagram.com/gyanpandit12/',
         Github: 'https://github.com/gyanimishra',
         facebook: 'https://www.facebook.com/people/Gyan-Prakash-Mishra/pfbid0U1rRTBoW5F2DmVjsNsHNoo2TrEMjBAHtbHKxHsm5t2Rb3zeHDsfYVUtYh1ifwpE6l/',
      },
    },
   
    {
      // memberImage: '/images/MuskanFinal2.png',
     memberImage: '/images/muskanHr.jpg', 
     memberName: 'Muskan Dosar',
      memberDesignation: 'HR',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/muskan-dosar-52a56017b/',
         twitter: 'https://twitter.com/Muskandosar?t=54wiH9QQ0TKuy-CgXAuTew&s=09',
        // youtube: '/',
        // facebook: '/',
      },
    },
    {
      memberImage: '/images/PrabsFinal.jpeg',
      memberName: 'Prabadhya Upadhya',
      memberDesignation: 'Sr. Full Stack Developer',
       memberSocial: {
        linkedin: 'https://www.linkedin.com/in/prabadhya-upadhyay-4272881a1/',
         twitter: 'https://twitter.com/Prabadhya_U',
        // youtube: '/',
        // facebook: '/',
      },
      
    },
    {
      // memberImage: '/images/AnushkaFinal1.png',
      memberImage: '/images/Aakash.jpg',
      memberName: 'Akash Rajput',
      memberDesignation: 'React Native',
       memberSocial: {
        linkedin: 'https://www.linkedin.com/in/akash-singh-sisodiya-874050166/',
        // twitter: '/',
        // youtube: '/',
        // facebook: '/',
      },
    },
    
   
  ];
  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24 cs-arrow_style2">
      {teamData.map((item, index) => (
        <Div key={index}>
          <Team
            memberImage={item.memberImage}
            memberName={item.memberName}
            memberDesignation={item.memberDesignation}
            memberSocial={item.memberSocial}
           
          />
        </Div>
      ))}
    </Slider>
  );
}

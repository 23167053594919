import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import Section from "../Div";
import "./skills.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ContactPage() {
  const [tab, setTab] = useState("Gurugram");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [project, setProject] = useState("");
  const [mobile, setMobile] = useState("");
  const [inquiry, setInquiry] = useState("");
  const [date, setDate] = useState(new Date());
  var currentDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  const iframeRef = useRef(null);
  function handleIframeLoad() {
    const iframeDocument = iframeRef.current.contentWindow.document;
    const mapElement = iframeDocument.getElementById("my-map");
    mapElement.classList.add("highlighted");
  }
  const notify = () =>
    toast("Thank you for your request. We will reach out to you soon.");
  pageTitle("Career");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmitHandel = (e) => {
    e.preventDefault();
    const data = [
      {
        id: Math.random(1 - 9),
        Name: name,
        Email: email,
        ProjectType: project,
        Mobile: mobile,
        Inquiry: inquiry,
        Date: currentDate,
      },
    ];
    // console.log(data);
    axios
      .post("https://sheetdb.io/api/v1/cidphhzrh3ly2", { data })
      .then((res) => console.log(res.data));
    setName("");
    setEmail("");
    setProject("");
    setMobile("");
    setInquiry("");
  };
  return (
    <>
      <ToastContainer />
      <PageHeading
        title="Career"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="career"
      />
      {/* <Spacing lg="150" md="80" /> */}
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6" >
            {/* <SectionHeading title="" subtitle="" /> */}
            <Spacing lg="55" md="30" />
            {/* <ContactInfoWidget withIcon /> */}
            <Spacing lg="0" md="50" />
           
          </Div>
          <Div className="choosejob-job-content">
            <iframe
              src="https://controlshift.freshteam.com/jobs/"
              frameborder="0"
              className="choosejobFrame"
              style={{ background: "#181818" }}
            ></iframe>
    
          </Div>
        </Div>
      </Div>
      {/* <Spacing lg="150" md="80" /> */}
 </>
  );
}

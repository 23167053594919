import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Team from '../Team';

export default function TeamPage() {
  pageTitle('Team');
  const teamData = [
    {
      memberImage: '/images/Rahul.png',
      memberName: 'Rahul Singh',
      memberDesignation: 'Co-Founder, Operations Head',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/rahulcontrolshift/',
      //   twitter: '/',
      //   youtube: '/',
      //   facebook: '/',
      },
    },
    {
      // memberImage: '/images/MuskanFinal2.png',
     memberImage: '/images/Vivek.jpeg', 
      memberName: 'Vivek Kumar',
      memberDesignation: 'Full Stack Developer',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/vivek-kumar-498507b8/',
        // twitter: '/',
        // youtube: '/',
        facebook: 'https://www.facebook.com/people/Vivek-Kumar/pfbid0UrtStx3D5CaNQuTkiHrcnUiZuCiuhoNygbNg1wYRMoQcQRpXdAqYyYGn7FYZv8Tel/',

      },
    },
    {
      memberImage: '/images/Shakir.jpg',
      memberName: 'Md. Shakir Hussain',
      memberDesignation: 'React Developer',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/md-shakir-hussain/',
        // twitter: '/',
        // youtube: '/',
         Github: 'https://github.com/mshakir082',
         Instagram: 'https://www.instagram.com/lordofgod08/',
        // facebook: '/',
      },
    },
    {
      memberImage: '/images/Gyan.jpg',
      memberName: 'Gyan Prakash Mishra',
      memberDesignation: 'Jr. Full stack Developer',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/prakash-gyanu/',
        // twitter: '/',
        // youtube: '/',
         Instagram: 'https://www.instagram.com/gyanpandit12/',
         Github: 'https://github.com/gyanimishra',
         facebook: 'https://www.facebook.com/people/Gyan-Prakash-Mishra/pfbid0U1rRTBoW5F2DmVjsNsHNoo2TrEMjBAHtbHKxHsm5t2Rb3zeHDsfYVUtYh1ifwpE6l/',
      },
    },
    {
      // memberImage: '/images/AnushkaFinal1.png',
      memberImage: '/images/Aakash.jpg',
      memberName: 'Akash Rajput',
      memberDesignation: 'React Native',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/akash-singh-sisodiya-874050166/',
        // twitter: '/',
        // youtube: '/',
        // facebook: '/',
      },
    },
    {
      memberImage: '/images/PrabsFinal.jpeg',
      memberName: 'Prabadhya Upadhya',
      memberDesignation: 'Sr. Full Stack Developer',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/prabadhya-upadhyay-4272881a1/',
         twitter: 'https://twitter.com/Prabadhya_U',
        // youtube: '/',
        // facebook: '/',
      },
    },
    {
      // memberImage: '/images/MuskanFinal2.png',
     memberImage: '/images/muskanHr.jpg', 
      memberName: 'Muskan Dosar',
      memberDesignation: 'HR',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/muskan-dosar-52a56017b/',
         twitter: 'https://twitter.com/Muskandosar?t=54wiH9QQ0TKuy-CgXAuTew&s=09',
        // youtube: '/',
        // facebook: '/',
      },
    },
 
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Our Team"
        bgSrc="images/team_hero_bg.jpeg"
        pageLinkText="Team"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Meet our awesome <br/>team members"
          subtitle="Our Team"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {teamData.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Team
                memberImage={item.memberImage}
                memberName={item.memberName}
                memberDesignation={item.memberDesignation}
                memberSocial={item.memberSocial}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>
        <Spacing lg="70" md="50" />
        <Div className="container">
          <Cta
            title="Let’s disscuse make <br />something <i>cool</i> together"
            btnText="Setup a call with us & Coffee is on us"
            btnLink="/contact us"
            bgSrc="/images/cta_bg.jpeg"
          />
        </Div>
      </Div>
    </>
  );
}

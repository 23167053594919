import React from 'react'
import Portfolio from '../Portfolio'
import Div from '../Div'
import Slider from 'react-slick';

export default function PortfolioSlider() {
  const portfolioData = [
    {
      title:'Our Product: Poocho',
      subtitle:'Corporate Networking App',
      href:'/portfolio/portfolio-details',
      src:'/images/Poocho.jpeg'
    },
    {
      title:'SHEQONOMI 2.O',
      subtitle:'Podcast App for Android based devices',
      href:'/portfolio/portfolio-details',
      src:'/images/portffolio2.jpeg'
    },
    {
      title:'Amazon Analytics',
      subtitle:'Project deals with Amazon AD analytics using Amazon APIs',
      href:'/portfolio/portfolio-details',
      src:'/images/Portfolio3.jpeg'
    },
    {
      title:'Wordpress Website',
      subtitle:'Exclusively done using Elementor plugin',
      href:'/portfolio/portfolio-details',
      src:'/images/akridata.jpeg'
    },
  ]
  
  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows:false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  
  return (
    <Slider {...settings} className='cs-slider cs-style3 cs-gap-24'>
      {portfolioData.map((item, index)=> (
        <Div key={index}>
          <Portfolio 
            title={item.title} 
            subtitle={item.subtitle}
            href={item.href}
            src={item.src}
          />
        </Div>
      ))}
    </Slider>
  )
}

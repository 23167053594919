import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Accordion from "../Accordion";
import Button from "../Button";
import Cta from "../Cta";
import IconBox from "../IconBox";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import TestimonialSlider from "../Slider/TestimonialSlider";
import Spacing from "../Spacing";
import { backendimagedata } from "./Skills";
import { nocodedata } from "./Skills";
import { frontendimagedata } from "./Skills";

export default function ServiceDetailsPage() {
  const [serviceTitle, setServiceTitle] = useState([]);
  const [serviceTitle1, setServiceTitle1] = useState([]);
  const [serviceTitle2, setServiceTitle2] = useState([]);
  pageTitle("Service Details");
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.serviceDetailsId == "UI-UX details") {
      setServiceTitle([
        ...serviceTitle,
        {
          title: "Mood board and Color palette",
          subtitle:
            "Creating a mood board and color palette in UI/UX design is crucial. We analyze project requirements, gather inspiration, and use tools like Canva or Figma to craft a mood board. We then choose a color palette that aligns with the inspiration. These will set the visual direction and mood for the project.",
        },
      ]);
      setServiceTitle1([
        ...serviceTitle1,
        {
          title: "Wireframes",
          subtitle:
            "We create wireframes to plan the structure and layout of the app, to focus on it’s usability and user experience. It also reduces the risk of potential design issues at a later stage. Wireframes also help us collaborate with the stakeholder and get their feedback",
        },
      ]);
      setServiceTitle2([
        ...serviceTitle2,
        {
          title: "UI/UX Design or Prototype",
          subtitle:
            "By integrating a Mood Board and wireframes, we can generate the final design for an application. This process leverages the Mood Board's visual direction to align the design with the intended aesthetic, while the wireframes facilitate the creation of a functional and user-friendly layout.",
        },
      ]);
    } else {
      setServiceTitle([
        ...serviceTitle2,
        {
          title: "UI/UX Design",
          subtitle:
            "By integrating a Mood Board and wireframes, we can generate the final design for an application. This process leverages the Mood Board's visual direction to align the design with the intended aesthetic, while the wireframes facilitate the creation of a functional and user-friendly layout.",
        },
      ]);
      setServiceTitle1([
        ...serviceTitle1,
        {
          title: "Development",
          subtitle:
            "We create wireframes to plan the structure and layout of the app, to focus on it’s usability and user experience. It also reduces the risk of potential design issues at a later stage. Wireframes also help us collaborate with the stakeholder and get their feedback",
        },
      ]);
      setServiceTitle2([
        ...serviceTitle2,
        {
          title: "Deployment",
          subtitle:
            "By integrating a Mood Board and wireframes, we can generate the final design for an application. This process leverages the Mood Board's visual direction to align the design with the intended aesthetic, while the wireframes facilitate the creation of a functional and user-friendly layout.",
        },
      ]);
    }
  }, [params.serviceDetailsId]);

  console.log(serviceTitle, serviceTitle1, serviceTitle2);
  return (
    <>
      <PageHeading
        title="Service Details"
        bgSrc="/images/service_hero_bg.jpeg"
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Design working process"
          subtitle={params.serviceDetailsId}
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          <Div className="col-lg-4">
            {serviceTitle?.map((el) => (
              <IconBox
                icon="/images/icons/service_icon_1.svg"
                // title= 'UI/UX design'
                title={el.title}
                subtitle={el.subtitle}
                // subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium also a doloremque laudantium, totam remain beatae vitae dictaro enim ipsam sunt explicabo."
              />
            ))}
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            {serviceTitle1?.map((el) => (
              <IconBox
                icon="/images/icons/service_icon_2.svg"
                // title="Developent"
                title={el.title}
                subtitle={el.subtitle}
                //subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium also a doloremque laudantium, totam remain beatae vitae dictaro enim ipsam sunt explicabo."
              />
            ))}
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            {serviceTitle2?.map((el) => (
              <IconBox
                icon="/images/icons/service_icon_3.svg"
                //title="Deployment"
                title={el.title}
                subtitle={el.subtitle}
                //subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium also a doloremque laudantium, totam remain beatae vitae dictaro enim ipsam sunt explicabo."
              />
            ))}
            <Spacing lg="30" md="30" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="120" md="50" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src="/images/service_img_1.jpeg"
                alt="Service"
                className="cs-radius_15 w-100"
              />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">
              Below our most design related services
            </h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-lg-6">
                <Button
                  btnLink="/service/service-details"
                  btnText="Web page design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/service/service-details"
                  btnText="eCommerce design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/service/service-details"
                  btnText="Landing page"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/service/service-details"
                  btnText="Email template"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/service/service-details"
                  btnText="Application design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                {/* <Button
                  btnLink="/service/service-details"
                  btnText="Illustration"
                  variant="cs-type2"
                /> */}
                <Spacing lg="0" md="10" />
              </Div>
              <Div className="col-lg-6">
                {/* <Button
                  btnLink="/service/service-details"
                  btnText="Infographic design"
                  variant="cs-type2"
                /> */}
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/service/service-details"
                  btnText="Mobile apps design"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                {/* <Button
                  btnLink="/service/service-details"
                  btnText="Banner, brochure, card"
                  variant="cs-type2"
                /> */}
                <Spacing lg="20" md="10" />
                {/* <Button
                  btnLink="/service/service-details"
                  btnText="Other design"
                  variant="cs-type2"
                /> */}
                <Spacing lg="20" md="10" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <TestimonialSlider />
      <div style={{ width: "100%", height: "850px" }}>
        <div style={{ textAlign: "center", marginTop: "80px" }}>
          <Div>
            <SectionHeading
              title="Our Engineers expertise Technologies "
              subtitle=""
            />
            <Spacing lg="90" md="45" />
          </Div>
        </div>

        <div className="expertise-main-container-content service-slider">
          {/* frontend image */}
          <div className="service-container">
            <div className="expertise-main-container-content-frontend-heading">
              <span>Front-end</span>
            </div>

            <div className="service-slide-track">
              {frontendimagedata.map((ele) => (
                <div className="slide">
                  <img src={ele.image} alt="" />
                </div>
              ))}
            </div>
          </div>

          {/* backend image */}
          <div class="service-container">
            <div class="expertise-main-container-content-frontend-heading">
              <span>Back-End</span>
            </div>
            <div class="service-slide-track">
              {backendimagedata.map((ele) => (
                <div class="slide">
                  <img src={ele.image} alt="" />
                </div>
              ))}
            </div>
          </div>

          {/* no-code less code image */}
          <div className="service-container">
            <div className="expertise-main-container-content-frontend-heading">
              <span>No-code/low code</span>
            </div>

            <div className="service-slide-track">
              {nocodedata.map((ele) => (
                <div className="slide">
                  <img src={ele.image} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <Spacing lg="145" md="80" /> */}
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>

        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="Some pre questions and answers"
                subtitle="FAQ’s"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div> */}

      {/* <Spacing lg="150" md="80" /> */}
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Setup a call with us & Coffee is on us"
          btnLink="/contact us"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}

import React from "react";
import Div from "../Div";
import AuthorWidget from "../Widget/AuthorWidget";
import RecentPost from "../Widget/RecentPost";
import SearchWidget from "../Widget/SearchWidget";
import SideMenuWidget from "../Widget/SideMenuWidget";
import TagWidget from "../Widget/TagWidget";
import medium_profile from '../../assets/Images/medium_profile.png'
export default function Sidebar() {
  const tagData = [
    {
      title: "Business",
      url: "/blog",
    },
    {
      title:'Business Development'
    },
    {
      title: "Innovation",
     url: "/blog",
    },
    {
      title: "Machine Learning",
     url: "/blog",
    },
    {
      title: "Recruitment",
     url: "/blog",
    },
    {
      title: "Hiring",
     url: "/blog",
    },
    {
      title: "Resources",
     url: "/blog",
    },
    {
      title: "Talent",
     url: "/blog",
    },
  ];
  const archiveData = [
    {
      title: "Archives",
     url: "/blog",
    },
    {
      title: "15 Aug 2022",
      url: "/",
    },
    {
      title: "20 Sep 2021",
      url: "/",
    },
    {
      title: "11 Dec 2020",
      url: "/",
    },
    {
      title: "25 jun 2020",
      url: "/",
    },
  ];
  // const categoryData = [
  //   {
  //     title: "Corporate",
  //     url: "/blog/blog-details",
  //   },
  //   {
  //     title: "Company",
  //     url: "/blog/blog-details",
  //   },
  //   {
  //     title: "Search Engine",
  //     url: "/blog/blog-details",
  //   },
  //   {
  //     title: "Information",
  //     url: "/blog/blog-details",
  //   },
  //   {
  //     title: "Painting",
  //     url: "/blog/blog-details",
  //   },
  // ];
  // const recentPostData = [
  //   {
  //     title: "How to studio setup...",
  //     thumb: "/images/recent_post_1.jpeg",
  //     href: "/blog/blog-details",
  //     date: "15 Aug 2022",
  //   },
  //   {
  //     title: "Creative people mind...",
  //     thumb: "/images/recent_post_2.jpeg",
  //     href: "/blog/blog-details",
  //     date: "14 Aug 2022",
  //   },
  //   {
  //     title: "AI take over human...",
  //     thumb: "/images/recent_post_3.jpeg",
  //     href: "/blog/blog-details",
  //     date: "13 Aug 2022",
  //   },
  //   {
  //     title: "You should now add...",
  //     thumb: "/images/recent_post_4.jpeg",
  //     href: "/blog/blog-details",
  //     date: "12 Aug 2022",
  //   },
  // ];
  return (
    <>
      <Div className="cs-sidebar_item">
        <AuthorWidget
          // src='/images/avatar_1.png'
          src={medium_profile}
          // name='Kuber Jontra'
          name="Control Shift"
          description="ControlShift Talent provides Consulting & Staff Augmentation Services across the Globe in Information Technology, Health Care, Finance & Accounting, Engg & HR,"
        />
      </Div>
      <Div className="cs-sidebar_item">
        <SearchWidget title="Search" />
      </Div>
      <Div className="cs-sidebar_item">
        {/* <SideMenuWidget title="Categories" data={categoryData} /> */}
        <SideMenuWidget title="Categories" />
      </Div>
      <Div className="cs-sidebar_item">
        <RecentPost title="Archives"  />
        {/* <RecentPost title="Archives" data={recentPostData} /> */}
      </Div>
      {/* <Div className="cs-sidebar_item">
        <SideMenuWidget title="Archives" data={archiveData} />
      </Div> */}
      <Div className="cs-sidebar_item">
        <TagWidget title="Tags" data={tagData} />
      </Div>
    </>
  );
}
